export const DIALOG_PASSWORD_CONSTANTS = {
  CANCEL: 'Cancel',
  CLOSED: 'Closed',
  CONFIRM: 'Confirm',
  IMG_REF: '../../../assets/driver.svg',
  KEY_CONFIRM_PASSWORD: 'confirmPassword',
  KEY_ERROR_INCORRECT: 'incorrect',
  KEY_ERROR_MINLENGTH: 'minlength',
  KEY_ERROR_NOTMATCH: 'notMatch',
  KEY_ERROR_PATTERN: 'pattern',
  KEY_ERROR_REQUIRED: 'required',
  KEY_ERROR_WHITESPACE: 'whitespace',
  KEY_PASSWORD: 'password',
  MIN_LENGTH_PASSWORD: 8,
  PASSWORD_TYPE: 'password',
  TEXT_TYPE: 'text'
}
