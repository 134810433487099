import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
  ShipmentSaleCharge,
  ShipmentSaleChargeBodySearch,
  ShipmentSaleChargeResponse
} from '../../interfaces/shipmentSaleCharge';

const URL = environment.tmsFreightCostApiUrl;

@Injectable()

/**
 * @description Service to make request for shipment sale charge.
 */
export class ShipmentSaleChargeProvider {
  /**
   * @description Initializes the variables of the service when it is instantiated.
   * @param {HttpClient} http - Service with methods to generate http request.
   */
  constructor(private http: HttpClient) { }

  /**
   * @description Gets shipment sale charges by tenantId and shipmentIds.
   * @param {string} tenantId - The current tenantId.
   * @param {Array<string>} shipmentIds - An array of shipmentIds.
   * @param {Array<string>} status - An optional array of status filters.
   * @returns {Promise<ShipmentSaleChargeResponse>} - The response containing shipment sale charges.
   */
  public async getShipmentSaleChargesByTenantAndShipmentIds(tenantId: string,
    shipmentIds: Array<string>, status?: Array<string>): Promise<ShipmentSaleChargeResponse> {
    const url = URL + `/shipmentSaleCharges/tenant/${tenantId}/shipmentIds`;
    const shipmentChargeBodySearch: ShipmentSaleChargeBodySearch = {
      shipmentIds: shipmentIds,
      status: status
    };

    return this.http.post<ShipmentSaleChargeResponse>(url, shipmentChargeBodySearch).toPromise();
  }

  /**
   * @description Generate a request to save or update the shipment sale charges.
   * @param {string} tenantId - The current tenantId.
   * @param { Array<ShipmentSaleCharge>} shipmentSaleCharges - An array of shipment sale charge to process.
   * @returns {Promise<ShipmentSaleChargeResponse>} - The response containing shipment sale charges.
   */
  public async saveShipmentSaleCharges(tenantId: string,
    shipmentSaleCharges: Array<ShipmentSaleCharge>): Promise<ShipmentSaleChargeResponse> {
    const url = `${URL}/shipmentSaleCharges/tenant/${tenantId}/salescharges`;

    return this.http.post<ShipmentSaleChargeResponse>(url, shipmentSaleCharges).toPromise();
  }
}
